import GGCPrimitives from './GGCPrimitives';
import $ from 'jquery';
import * as d3 from 'd3';
import {graphBaseGGC} from './graphbaseggc';
import i18next from 'i18next';
import { isRuntimeOnly } from '../utils/Utils';

export class pieGGC extends graphBaseGGC {
    constructor(args) {
        super(args);

        this.superClass = 'pieGGC';
        
        this.labelFont = undefined;
        this.labelFontStyle = undefined; // UBI
        this.labelColor = 'black';

        this.labelPosition = 'interior';   // not supported 2024 'hover' | 'exterior' | 'interior';
        this.outlineColor = 'white'.toColorRef();   // ref
        this.outlineWidth = 2;
        this.outlineStrokeDashArray = '0';

        this.labelHolders = undefined;
        this.radius = undefined;
        this.innerRadius = 0;

        this.labelRadiusOffset = 20;
        this.svgId = undefined;
        this.pieGs = undefined
        this.hierarchyData = undefined;
        this.viewBoxAdjustHeight = 0;


        this._init(args);
    }
    getGraphType() {
        if (this.innerRadius === null || this.innerRadius === 0) {
            return 'pie';
        } else {
            return 'donut';
        }
    }
    _init(args) {
        //2023 this._base_init(args);
        this._initVariables(args);
    }
    _saveProps() {
        var self = this;

        var base = self._base_saveProps();
        var me = {
            innerRadius: self.innerRadius,
            labelPosition: self.labelPosition,
            labelFont: self.labelFont,
            labelFontStyle: self.labelFontStyle,
            labelColor: self.labelColor,
            outlineWidth: self.outlineWidth,
            outlineColor: self.outlineColor,
            outlineStrokeDashArray: self.outlineStrokeDashArray,
            viewBoxAdjustHeight: self.viewBoxAdjustHeight,
        };

        return { ...base, ...me };
    }
    _initVariables(args) {
        // Put any if (args.hasOwnProperty('prop')) checks here...
        if (args.hasOwnProperty('innerRadius') && args.innerRadius)
            this.innerRadius = args.innerRadius; // number (pixels) or percent of radius ('50%') 

        if (args.hasOwnProperty('labelPosition')) { // interior, exterior
            this.labelPosition = args.labelPosition;
        }

        if (args.hasOwnProperty('labelFont'))
            this.labelFont = args.labelFont;

        else
            this.labelFont = this.font;
        if (args.hasOwnProperty('labelFontStyle'))
            this.labelFontStyle = args.labelFontStyle;

        else
            this.labelFontStyle = '';
        if (args.hasOwnProperty('labelColor'))
            this.labelColor = args.labelColor;

        if (args.hasOwnProperty('outlineWidth'))
            this.outlineWidth = args.outlineWidth;

        if (args.hasOwnProperty('outlineColor'))
            this.outlineColor = GGCPrimitives._argsToColorRef(args, 'outlineColor');

        if (args.hasOwnProperty('outlineStrokeDashArray'))
            this.outlineStrokeDashArray = args.outlineStrokeDashArray;

        if (args.hasOwnProperty('viewBox') && isRuntimeOnly()) {
            this.viewBox = args.viewBox;
            if (args.hasOwnProperty('viewBoxAdjustHeight')) {
                this.viewBoxAdjustHeight = +args.viewBoxAdjustHeight;
            }
        }
    
    }
    
	setupColorDomainFromHierarchy() {
		let self = this;

        // This is a transition state, so get out.
        if (!this.data || !this.data.children) return;

		let newBaseNames = [];
		for (let i = 0; i < this.data.children.length; i++) {
			newBaseNames.push(this.data.children[i].data[0]/*.name*/);
		}

        // 1st, if exists, adjust valueStatus to match baseNames.  So, if we have valueStatus
        // [1, 1, .025] and current baseNames are ['midtown', 'beltline', 'river street']
        // and we are moving to ['midtown', 'river street'] - we need to align the valueStatus to be
        // [1, 0.25]
        if (self.valueStatus && self.baseNames) {
            let newValueStatus = [];
            for (let i=0; i<newBaseNames.length; i++) {
                let ixOld = self.baseNames.findIndex((itm) => itm === newBaseNames[i])
                if (ixOld !== -1) {
                    newValueStatus.push(self.valueStatus[ixOld]);
                }
            }
            self.valueStatus = newValueStatus;
        }

		this.baseNames = newBaseNames;
        // 2025-02-13 added this because the legend wasn't displaying all Names. set valueNames and duplicate so they show up
        // didn't spend much time on this, so could be completely messed up.
        this.valueNames = newBaseNames;
        this.setValueNamesDuplicate();
		this.color.domain(newBaseNames);
	}
    resize(opts) {
        var self = this;
        let svgWidth, svgHeight;
        let width, height;
        let gonnaDraw = true;

        // We need to check this because if we don't draw, then we've set the outerWidth/Height to something
        // from the viewBox - which is not what we want because _getData calls refresh with self.outerWidth/outerHeight
        if (typeof (this.data) === 'undefined' || !this.data || (opts.hasOwnProperty('refreshData') && opts.refreshData)) {
            if (!(opts.hasOwnProperty('refreshData') && opts.refreshData)) {
                gonnaDraw = false;
            }
        }


        if (isRuntimeOnly() && this.viewBox && gonnaDraw) {
            const vbCoords = this.viewBox.split(' ');
            width = parseInt(vbCoords[2]);
            height = parseInt(vbCoords[3]);
            svgWidth = opts.width;
            svgHeight = opts.height + this.viewBoxAdjustHeight;
        } else {
            width = opts.width;
            height = opts.height;
        }

        self.outerWidth = width;
        self.outerHeight = height;

        if (typeof (this.data) === 'undefined' || !this.data || (opts.hasOwnProperty('refreshData') && opts.refreshData)) {
            if (this.ajax) {
                this._getData((opts.hasOwnProperty('refreshData') && opts.refreshData));
            }
            if (!(opts.hasOwnProperty('refreshData') && opts.refreshData)) {
                return;
            }
        }

        this.origWidth = self.outerWidth;
        this.origHeight = self.outerHeight;
        if (self._pagingControl) {
            this.origHeight -= $(self._pagingControl).height();
            this.viewBoxAdjustHeight = -$(self._pagingControl).height();
        }
        if (self._sortDivId) {    // _sortTableId 2023  - outer div is _sortDivId
            this.origHeight -= $('#' + self._sortDivId).height();
            this.viewBoxAdjustHeight = -$('#' + self._sortDivId).height();
        }
        //2023 GGC $(this.outerContainer).height(this.outerHeight);
        
        if (this.hasOwnProperty('svg') && this.svg)
            this.svg.remove();
        if (this.hasOwnProperty('tooltip') && this.tooltip)
            this.tooltip.remove();
        // remove any leftover popovers
        $('.popover').popover('hide');

        this.width = this.origWidth - this.margin.left - this.margin.right - this.legendSpace;
        this.height = this.origHeight - this.margin.top - this.margin.bottom;

        this.svgId = self._genId();

        if (!isRuntimeOnly()) {
            svgWidth = this.width + this.margin.left + this.margin.right + this.legendSpace;
            svgHeight = this.height + this.margin.top + this.margin.bottom;
            this.viewBox = [0, 0, svgWidth, svgHeight].join(' ');
        }

        this.svg = d3.select(this.container).append("svg")
            .attr("width", svgWidth)
            .attr("height", svgHeight)
            .attr("viewBox", this.viewBox)
            .attr("preserveAspectRatio", "xMidYMid meet")
            .attr('id', self.svgId);


        this.radius = (Math.min(this.height, this.width) * .90) / 2; // 75% of min size

        if (this.width > this.height) {
            let middleX = ((this.width / 2) + this.radius) / 2;
            this.diagram = this.svg.append("g")
                .attr("transform", "translate(" + (this.margin.left + middleX) + "," + (this.margin.top + this.height / 2) + ")");
            this.xLateX = this.margin.left + middleX;
            this.xLateY = this.margin.top + this.height / 2;
        } else {
            this.diagram = this.svg.append("g")
                .attr("transform", "translate(" + (this.margin.left + this.width / 2) + "," + (this.margin.top + this.height / 2) + ")");
            this.xLateX = this.margin.left + this.width / 2;
            this.xLateY = this.margin.top + this.height / 2;
        }

        
        this.setupColorDomainFromHierarchy();
        this._colorDomainSet(['outlineColor']);

        self._fillBackground(this.svg);

        this.ixLabel = this.categoryNames.length - 1; // label is in last entry of categoryNames, e.g. City


        if (typeof this.innerRadius == 'string') {
            if (/^\d+$/.test(this.innerRadius)) {
                this.innerRadius = +this.innerRadius;
            } else if (/^\d+%$/.test(this.innerRadius)) {
                this.innerRadius = (+(this.innerRadius.substring(0, this.innerRadius.length - 1)) * this.radius) / 100;
            } else {
                this.innerRadius = 0;
            }
        }

        self.tooltipCreate(1);

        self.pieWidth = parseInt((this.radius-this.innerRadius) / this.data.height) - this.data.height;

        let filteredData = self.getFilteredData(this.data);

        self._drawPiesAndLabels(filteredData);

        this._base_makeLegend({ resize: true });

        this.checkEditMode();


    }
    toggleEdit() {
        var self = this;
        return self._toggleEdit();
    }

    bfs(root, lix) {
        // enqueue the root
        let queue = [root];//, out = [];
        // since we need all the nodes at a particular level we will need to iterate and push all the children in the queue
        let levelNumber = 0;
        while(queue.length){
                let size = queue.length;
                let levelNodes = [];
                while(size){
                    // dequeue the  element
                    let node = queue.shift();
                    if (node.depth === lix) {
                        levelNodes.push(node);
                    }
                    
                    // gather all the children of node dequeued and enqueue them(left/right nodes)
                    if (node.children) {
                        for (let j = 0; j < node.children.length; j++) {
                            queue.push(node.children[j])
                        }
                    }
                    size--;
                }
                if (levelNumber === lix) {
                    return levelNodes;
                }
                levelNumber++;
                //out.push(levelNodes);
            }
        return [];
    }

    drawPieChart = function(_data, index, opacity) {
        let self = this;
        // 1st get all children at depth==index
        let theseChildren = self.bfs(_data, index+1);


        var pie = d3.pie()
            .sort(null)
            .value(function(d) {
                return d.value/*nodeData.population*/;
            });
        var arc = d3.arc()
            .outerRadius(this.innerRadius + (index + 1) * self.pieWidth - 1)
            .innerRadius(this.innerRadius + index * self.pieWidth);
    
        var g = self.diagram.selectAll(".arc" + index).data(pie(theseChildren)).enter().append("g")
            .attr("class", "arc" + index);
    
        g.append("path").attr("d", arc)
            .style("fill", function(d) {
                let found1st = false;
                while (!found1st) {
                    if (d.hasOwnProperty('depth') && d.depth === 1) {
                        found1st = true;
                    } else if (d.data && d.data.hasOwnProperty('depth') && d.data.depth === 1) {
                        found1st = true;
                        d = d.data;
                    } else {
                        if (d.hasOwnProperty('parent')) {
                            d = d.parent;
                        } else if (d.data && d.data.hasOwnProperty('parent')) {
                            d = d.data.parent;
                        } else {
                            // error!
                            found1st = true;
                        }
                    }
                }
                //return self.color(d.data[0]);
                let ix = self.baseNames.findIndex((itm) => itm === d.data[0]);
                return self._getFillColor(ix);

            })
            .style("opacity", opacity)
            .attr('stroke', function () {
                return GGCPrimitives._getColorFromRef(self.outlineColor);
            })
            .attr('stroke-width', self.outlineWidth)
            .attr('stroke-opacity', function () {
                return GGCPrimitives._getOpacityFromRef(self.outlineColor);
            })
            .attr('stroke-dasharray', function () {
                return self.outlineStrokeDashArray;
            })
            .attr("class", function (d) {
                let classLabel = self._fixName(d.data[0]);
                return "class" + classLabel + ' arcGGC';
            })
            .on("mouseover", function (event, d) { // v6 added event, removed ixm
                if (self.editMode) return;


                d3.select(this).transition()
                    .duration('200')
                    .style('opacity', `${opacity*.85}`)

                if (self.labelPosition === 'hover') {

                    var total = self.data.value;


                    var percent = Math.round(1000 * d.data[1] / total) / 10;

                    self.tooltipSetRow(0, d.data[0], percent + '%: ' + d.data[1]);

                    //v5 let tx = d3.event.offsetX;
                    //v5 let ty = d3.event.offsetY;
                    const coords = d3.pointer(event);
                    //console.log(coords);
                    //console.log(self.xLateX, self.xLateY);
                    let tx = coords[0];
                    let ty = coords[1];
                    self.tooltipShow(tx + self.xLateX, ty + self.xLateY);

                }

            })
            .on("mouseout", function (event, d) {
                if (self.editMode) return;


                d3.select(this).transition()
                    .duration('200')
                    .style('opacity', `${opacity}`)


                if (self.labelPosition === 'hover')
                    self.tooltipHide();
            })
            .on('mousemove', function (event, d) {      // v6 added event
                if (self.editMode) return;

                if (self.labelPosition === 'hover') {
                    //v5 let tx = d3.event.offsetX;
                    //v5 let ty = d3.event.offsetY;
                    const coords = d3.pointer(event);
                    let tx = coords[0];
                    let ty = coords[1];
                    self.tooltipShow(tx + self.xLateX, ty + self.xLateY);
                }
            })
            .on("click", function (event, d) {
                if (self.editMode) return;

                var tpsf_event = {
                    // eid:
                    // schema:
                    table: self.tableName,
                    field: self.categoryNames[d.data.depth-1],
                    value: d.data.data[0]
                };
                self.categoryClick(tpsf_event);
            })
            .append('title')
            .text(function(d) {
                return d.data.data[0] + `\n${i18next.t("graph.lbl.votes")} ` + d.data.value;
            })

            var skew = 0;
            if (self.labelFontStyle.indexOf('I') !== -1) {
                skew = -self._italicSkewDegrees;
            }

    
            self.labelHolders = g.append("text")
            .attr("transform", function(d) {
                return `translate(${arc.centroid(d)}) skewX(${skew})`;
            })
            .attr("dy", ".35em").style("text-anchor", "middle")
            .text(function(d) {
                if (d.data.depth === 1) {
                    return d.data.value;
                } else {
                    return d.data.data[0] + "\n" + d.data.value;
                }
            })
            .attr('fill', this.labelColor)
            .style("font", this.labelFont)
            .style('font-weight', this.labelFontStyle.indexOf('B') !== -1 ? 700 : 400)
            .attr('text-decoration', this.labelFontStyle.indexOf('U') !== -1 ? 'underline' : 'none')

            return g;

    }

    _setData(dimhier) {

        this.hierarchyData = dimhier;

        const childAccFn = ([ key, value ]) => value.size && Array.from(value)
        const root = d3.hierarchy([null, dimhier], childAccFn)  // dimhier)
            .sum(([key, value]) => value)
//2024-04-21            .sort((a, b) => b.value - a.value);
        this.data = d3.partition()
            .size([2 * Math.PI, root.height + 1])(root);

    }

    _drawPiesAndLabels(data) {
        let self = this;
        let opacStep = .6 / data.height;

        if (self.pieGs) {
            for (let i=0; i<self.pieGs.length; i++) {
                self.pieGs[i].remove();
            }
        }
        self.pieGs = [];
        for (let i=0; i<data.height; i++) {
            self.pieGs.push( this.drawPieChart(data, i, 1-i*opacStep) );
        }
    }

    getFilteredData() {
        let self = this;

        let filteredData = this.data;

        // self.baseNames line up with self.valueStatus. e.g.
        // ['Soup Dumplings', 'Nashville Hot Chicken', 'Pulled Pork Nachos']
        // [0.25, 0.5, 0.5]   - we clicked on the legend for Nashvill Hot Chicken, so it changes the
        // opacity value to 0.25 (hidden).  So, filter that out here.
        if (self.valueStatus && self.baseNames && self.valueStatus.length === self.baseNames.length) {
            let filtered = false;
            for (let i = 0; i < self.valueStatus.length; i++) {
                if (self.valueStatus[i] === this._hiddenOpacity) {
                    filtered = true;
                }
            }

            if (filtered) {
                let filteredDim = new d3.InternMap(self.hierarchyData);
                for (let i = 0; i < self.valueStatus.length; i++) {
                    if (self.valueStatus[i] === this._hiddenOpacity) {
                        filteredDim.delete(self.baseNames[i]);
                    }
                }

                const childAccFn = ([key, value]) => value.size && Array.from(value)
                const root = d3.hierarchy([null, filteredDim], childAccFn)  // dimhier)
                    .sum(([key, value]) => value)
//2024-04-21                    .sort((a, b) => b.value - a.value);

                filteredData = d3.partition()
                    .size([2 * Math.PI, root.height + 1])(root);
            }

        }
        return filteredData;
    }

    refresh(opts) {
        var self = this;

        self.setupColorDomainFromHierarchy();

        self._colorDomainSet(['outlineColor']); // really updates palette based on interpolate or not

        let filteredData = self.getFilteredData(this.data);

        self._drawPiesAndLabels(filteredData);

        self._base_makeLegend({ resize: true });
    }
    checkEditMode() {
        var self = this;

        if (self.editMode) {

            var bbox = self.labelHolders
                .last()
                .node()
                .getBBox()

            var x, y;
            x = bbox.x;
            y = bbox.y;

                var lbl = '';
                self._drawCircleEditText({       // old _drawCircleEditLabels({
                    selector: self.diagram,
                    kind: 'pielbl',
                    title: i18next.t("graph.edit.pie_label"),
                    label: lbl,
                    drawLabel: false,
                    x: x,
                    y: y,
                    r: 12,
                    placement: 'right',
                    variables: {
                        fontProp: 'labelFont',
                        styleProp: 'labelFontStyle',
                        colorProp: 'labelColor',
                        labelPositionProp: 'labelPosition'
                    }

                });

                // go to 45 degrees up and left on arc edge
                d3.arc()
                    .innerRadius(this.innerRadius)
                    .outerRadius(this.radius);

                x = -self.radius * Math.cos(Math.PI / 4);
                y = -self.radius * Math.sin(Math.PI / 4);

                self._drawCircleEditOutline({
                    selector: self.diagram,
                    kind: 'piepos',
                    title: i18next.t("graph.edit.outline"),
                    x: x,
                    y: y,
                    r: 12,
                    placement: 'right',
                    variables: {
                        strokeWidthProp: 'outlineWidth',
                        strokeDashArrayProp: 'outlineStrokeDashArray',
                        colorRefProp: 'outlineColor'
                    }

                });

            // Background...center 0,0 is center of pie, so need to offset back
            x = -(3 * this.width / 8);
            y = -(3 * this.height / 8);
            self._drawCircleEditColor({
                selector: self.diagram,
                kind: 'bgcolor',
                title: i18next.t("graph.edit.background"),
                x: x,
                y: y,
                r: 12,
                placement: 'right',
                variables: {
                    colorRefProp: 'backgroundColor'
                }

            });

            // Draw legend edits
            self._baseEditMode();
        }
    }
    /* removed 2024 for hierarchical pie chart
    _drawCircleEditLabels(args) {
        var self = this;

        var sContent = 
            '<input type="radio" name="pieinout" value="interior"><div style="align-self:center; margin-left: 4px; margin-right: 4px;">' + i18next.t("graph.edit.interior") + '</div>' +
            '<input type="radio" name="pieinout" value="exterior"><div style="align-self:center; margin-left: 4px; margin-right: 4px;">' + i18next.t("graph.edit.exterior") +'</div>' +
            '<input type="radio" name="pieinout" value="hover"><div style="align-self:center; margin-left: 4px; margin-right: 4px;">' + i18next.t("graph.edit.hover") + '</div>';

        args.insertContentBefore = sContent;

        args.insertedCallback = function (me, popSel) {
            if (args.hasOwnProperty('variables')) {
                var vars = args.variables;
                $('input[name=pieinout][value="' + me[vars.labelPositionProp] + '"]').prop('checked', true);
                $('input[type=radio][name=pieinout]').change(function () {
                    me[vars.labelPositionProp] = this.value;
                    me._refresh();
                });
            }


        };

        self._drawCircleEditText(args);

    }
    */
}

//pieGGC.prototype = new graphBaseGGC();

